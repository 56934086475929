import React from "react";
import { BsRocketTakeoffFill } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import "./Quick.scss";

const isDateInFuture = (targetDate) => {
	const targetDateObject = new Date(targetDate);
	const currentDate = new Date();
	return targetDateObject > currentDate;
};


const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0'); // Add leading zero to day
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero to month
    const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year

    return `${day}-${month}-${year}`;
};

const Quick = (props) => {
	return (
		<div className="quick-content">
			<div className="h1">
				{props.task ? props.task.subject : ""}
				<span>
					<GoDotFill /> {props.task.status}
				</span>
			</div>
			<ul>
				<li>
					<span>
						<FaLocationDot /> {props.task.location}
					</span>
					<span className="request-text">
						{" "}
						<span>Requests by {props.task.customer}</span> •{" "}
						<span>{props.task.company}</span> • <span>Task number {props.task.name}</span>
					</span>
				</li>

				<li>
					<span>
						<BsRocketTakeoffFill />{" "}
						{props.task.confirmed_date ?
						<> {isDateInFuture(new Date(props.task.confirmed_date))
							? "Starts on"
							: "Started on"}{" "}
						{/* Monday 21 July 2023 */}
						{formatDate(props.task.confirmed_date)}
						</>  : "Not scheduled"
					}
					</span>
				</li>
			</ul>
		</div>
	);
};

export default Quick;
