import { Outlet, Navigate } from "react-router-dom";

const isLoggedIn = () => {
  return localStorage.getItem("is_logged_in") === "true";
};

const PrivateRoutes = () => {
  let auth = isLoggedIn()
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
