import ReactApexChart from "react-apexcharts";
import "./ApexPieChart.css"; // Import the CSS file for custom styling

const ApexBarChart = ({ requestUrgency }) => {
  const totalRequests = Object.values(requestUrgency).reduce((acc, count) => acc + count, 0);

  return (
    <div className="wrapper">
      <div className="stacked-bar-chart">
        <div className="__chart-wrap">
          <div className="__chart">
            <div className="borders">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="bars">
              <span
                style={{
                  backgroundColor: "#577dff",
                  width: `${
                    (requestUrgency["Standard"] / totalRequests) * 100
                  }%`,
                }}
              >
                {requestUrgency["Standard"]}
              </span>
              <span
                style={{
                  backgroundColor: "#a9b8ea",
                  width: `${(requestUrgency["High"] / totalRequests) * 100}%`,
                }}
              >
                {requestUrgency["High"]}
              </span>
              <span
                style={{
                  backgroundColor: "#3e4756",
                  width: `${
                    (requestUrgency["Very High"] / totalRequests) * 100
                  }%`,
                }}
              >
                {requestUrgency["Very High"]}
              </span>
              <span
                style={{
                  backgroundColor: "#F9CBFD",
                  width: `${
                    (requestUrgency["Emergency"] / totalRequests) * 100
                  }%`,
                }}
              >
                {requestUrgency["Emergency"]}
              </span>
            </div>
          </div>
          <div className="indicators">
            <div className="indicator">
              <div
                className="color"
                style={{ backgroundColor: "#577dff" }}
              ></div>
              <div className="text">Standard</div>
            </div>
            <div className="indicator">
              <div
                className="color"
                style={{ backgroundColor: "#a9b8ea" }}
              ></div>
              <div className="text">High</div>
            </div>
            <div className="indicator">
              <div
                className="color"
                style={{ backgroundColor: "#3e4756" }}
              ></div>
              <div className="text">Very High</div>
            </div>
            <div className="indicator">
              <div
                className="color"
                style={{ backgroundColor: "#F9CBFD" }}
              ></div>
              <div className="text">Emergency</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApexBarChart;
