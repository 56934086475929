import ReactApexChart from "react-apexcharts";

function ApexBarChart3({ data, is_from_met }) {
  const chartData = {
    options: {
      chart: {
        type: "bar",
        stacked: true,
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        labels: {
          rotateAlways: true,
          rotate: -45,
          style: {
            fontSize: "11px", // Set the font size for labels
          },
        },
      },
      colors: !is_from_met
        ?  [
          "#F9CBFD",
          "#D1DCFD",
          "#819EFB",
          "#546FC3",
          "#3A5788",
          "#253057",
          ].reverse():  ["#000d39", "#f9395b"],
		plotOptions: {
			bar: {
			  horizontal: false,
			  dataLabels: {
				total: {
				  enabled: !is_from_met,
				  style: {
					fontSize: "13px",
					fontWeight: 400,
				  },
				},
			  },
			},
		  },
      legend: {
        show: false, // Set this to false to hide the legend
      },
    },
    series: data,
  };
  console.log(is_from_met);
  return (
    <div
      style={{
		paddingTop: 20,
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 30,
        paddingLeft: 0,
      }}
      className="wrapper"
    >
      <div className="stacked-bar-chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={260}
        />
      </div>
    </div>
  );
}

export default ApexBarChart3;
