import React, { useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Modal from "react-modal";
import OtpInput from "react-otp-input";
import {
  useGetLogin,
  usePasswordResetWithCreds
} from "../api/requests";
import { toast } from "react-toastify";
import { useEffect } from "react";

const PassswordReset = () => {
  const [otp, setOtp] = useState("");
  const [loginResponse, setLoginResponse] = useState({});
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [errorText, setErrorText] = useState("");

  let { token } = useParams();

  const [submitButtonText, setSubmitButtonText] = useState("Submit");

  const { mutateAsync: mutate, isPending, isLoading } = usePasswordResetWithCreds();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setErrorText("Passwords do not match.")
      toast.error("Passwords do not match.");
      return;
    }
    if (password.length < 8) {
        setErrorText("Password must be at least 8 characters long.")
    }

    setSubmitButtonText("Submitting...");
    const status = await mutate({
      new_password: password,
      key: token,
      old_password: "",
      cmd: "frappe.core.doctype.user.user.update_password",
      logout_all_sessions: 1
    });

    setErrorText("")
    if (status == 200) {
      setSubmitButtonText("Done")
      toast.info("Password reset successfully.");
      navigate("/login", { replace: true });
    }
    else {
      setSubmitButtonText("Submit")
      if (status == 401) {
        toast.error("Link has expired!")
      } else {
        toast.error("Password reset failed, Please try again later.");
      }
    }

  };




  return (
    <>
      <section
        className="login-section"
        style={{
          background: `url('/images/login-bg.png') no-repeat center center / cover`,
        }}
      >
        <div className="login-slider-area">
          <div className="logo">
            <Link to="/">
              <img
                className="logo-black"
                src="/images/logo-black.svg"
                width={121}
                alt=""
              />
              <img
                className="logo-white"
                src="/images/logo-white.svg"
                width={121}
                alt=""
              />
            </Link>
          </div>
          <div className="my-auto pb-5">
            <Swiper
              slidesPerView={1}
              spaceBetween={100}
              pagination={{
                clickable: true,
                className: "slider-pagination",
              }}
              modules={[Pagination, Autoplay]}
              autoplay={{
                delay: 5000,
              }}
            >
              <SwiperSlide>
                <div className="slider-item">
                  <h6 className="title">
                    Explore historic tasks seamlessly, understanding its
                    details, contributors and tracking time with precision.
                  </h6>
                  <p>We enjoy details.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slider-item">
                  <h6 className="title">
                    Gauge performance in a clear way, comprehending workload
                    variations and efficiency across different locations.
                  </h6>
                  <p>We prioritize performance.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slider-item">
                  <h6 className="title">
                    Get clarity on invoice history, effortlessly accessing past
                    invoices and structured statements of work.
                  </h6>
                  <p>We bring clarity.</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div
          className="login-form-area"
          style={{
            background: `url('/images/login-bg.png') no-repeat center center / cover`,
          }}
        >
          <div className="logo d-md-none w-100">
            <Link to="/">
              <img
                className="logo-white"
                src="/images/logo-white.svg"
                width={121}
                alt=""
              />
            </Link>
          </div>
          <form onSubmit={handleSubmit} className="login-form">
            <h5 className="title">Reset Password</h5>
            <div className="form-group">
              <label className="form--label">Password</label>
              <input
                type="password"
                required
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className="form-control form--control"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
                title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
              />
            </div>
            <div className="form-group mb-29">
              <label className="form--label">Confirm Password</label>
              <input
                type="password"
                required
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                className="form-control form--control"
              />
                <p style={{fontSize:"12px", paddingBottom: 0, marginTop:10}}>
                   {
                    errorText
                   }
                </p>

            </div>
            <div className="form-group" style={{marginTop: 5}}>
              <button type="submit" disabled={isPending} className="btn">
                {submitButtonText}
              </button>
            </div>
          </form>
        </div>


      </section>
    </>
  )

}

export default PassswordReset;