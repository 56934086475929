import React, { useState } from "react";
import "./Tracking.scss";
import { BiTimeFive } from "react-icons/bi";
import { PiNotepadLight } from "react-icons/pi";
import { AiOutlineUser, AiOutlineCalendar } from "react-icons/ai";
import { Link } from "react-router-dom";

const timeStringToDate = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  return new Date(2000, 1, 1, hours, minutes, seconds);
};
const convertDateFormat = (inputDate) => {
  const parts = inputDate.split('-');
  const day = String(parts[2]).padStart(2, '0');
  const month = String(parts[1]).padStart(2, '0');
  const year = String(parts[0]).slice(2); // Get last two digits of the year

  return `${day}-${month}-${year}`;
};
const timeDifferenceFormatted = (start, end) => {
  const startTime = timeStringToDate(start);
  let endTime = timeStringToDate(end);

  // If end time is before start time, add a day to end time
  if (endTime < startTime) {
    endTime.setDate(endTime.getDate() + 1);
  }

  const differenceInMillis = Math.abs(endTime - startTime);
  const differenceInHours = Math.floor(differenceInMillis / (1000 * 60 * 60)); // Calculate whole hours
  const differenceInMinutes = Math.floor(
    (differenceInMillis % (1000 * 60 * 60)) / (1000 * 60)
  ); // Calculate remaining minutes

  // Format the output
  const formattedHours = String(differenceInHours).padStart(2, "0"); // Ensure two-digit representation
  const formattedMinutes = String(differenceInMinutes).padStart(2, "0"); // Ensure two-digit representation

  return `${formattedHours}:${formattedMinutes}`;
};

const timeDifferenceInHoursMilliSecond = (startTime, endTime) => {
  const start = new Date(`2000-01-01 ${startTime}`);
  let end = new Date(`2000-01-01 ${endTime}`);

  // If end time is before start time, add a day to end time
  if (end < start) {
    end.setDate(end.getDate() + 1);
  }

  const differenceInMillis = Math.abs(end - start);
  return differenceInMillis / (1000 * 60 * 60); // Convert milliseconds to hours
};

const convertDecimalToHoursMinutes = (decimalHours) => {
  // Calculate hours and minutes
  const hours = Math.floor(decimalHours);
  const minutes = Math.round((decimalHours - hours) * 60);

  // Format hours and minutes
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};

const calculateTotalHoursWorked = (tasks) => {
  let totalHours = 0;
  tasks.forEach((task) => {
    const hoursWorked = timeDifferenceInHoursMilliSecond(
      task.start_time,
      task.end_time
    );
    totalHours += hoursWorked;
  });
  return totalHours;
};

const Tracking = (props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="tracking-content box-bg">
      <div>
        <div className="h3 fw-semibold">Time tracking</div>
        <div className="h5">
          Total hours |{" "}
          <span className="fw-semibold">
            {convertDecimalToHoursMinutes(calculateTotalHoursWorked(props.task))} hrs
          </span>
        </div>

        <div className="table-responsive">
          <div className="table-con">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <AiOutlineUser /> Technician
                  </th>
                  <th>
                    <AiOutlineCalendar /> Date
                  </th>
                  <th>
                    <BiTimeFive /> Hours
                  </th>
                </tr>
              </thead>
              <tbody
                id="main-content"
                style={{ height: "auto", overflow: "hidden" }}
              >
                {(props.task ? props.task : []).map((item) => (
                  <tr key={item.name}>
                    <td>{item.technician_name}</td>
                    <td>{convertDateFormat(item.date)}</td>
                    <td>
                      {timeDifferenceFormatted(item.start_time, item.end_time)}
                    </td>
                  </tr>
                ))}
              </tbody>
              <a
                style={{ marginTop: "1rem", display: (props.task && props.task.length < 5 ? "none" : "block") }}
                onClick={(e) => {
                  if (!expanded)
                    document.getElementById("main-content").style.height =
                      "auto";
                  else
                    document.getElementById("main-content").style.height =
                      "270px";
                  setExpanded(!expanded);
                }}
                to="/"
                className="show-more"
              >
                {expanded ? "Show Less" : "Show More"}
              </a>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tracking;
