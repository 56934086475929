import React from "react";
import WorkVisit from "../Components/Requests/WorkVisit/WorkVisit";

const WorkVisitRequests = () => {
  return (
    <>
      <div className="container">
        <WorkVisit/>
      </div>
    </>
  );
};

export default WorkVisitRequests;
