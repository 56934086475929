import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SelectControl from "../Components/SelectControl";
import ApexPieChart from "../Components/apex-chart/ApexPieChart";
import ApexBarChart from "../Components/apex-chart/ApexBarChart";
import ApexBarChart2 from "../Components/apex-chart/ApexBarChart2";
import ApexBarChart3 from "../Components/apex-chart/ApexBarChart3";
import {
  useGetTotalRequestsLastQuarter,
  useGetFirstResponseTimeLastQuarter,
  useGetOutstandingRequestsLastQuarter,
  useGetRequestUrgency,
  useGetTaskLevelMetrics,
  useGetCities,
  useGetFiscalYears,
  useGetHoursWorkedStat,
  useGetRequestsStat,
  useGetMetKPIs,
  useGetFirstResponseTimeKPIs,
  useGetConfirmationTimeKPIs,
  useGetCompanyName,
} from "../api/requests";
import { toast } from "react-toastify";

const Dashboards = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("is_logged_in") === "true" && localStorage.getItem("company") === null || localStorage.getItem("company") === undefined) {
      toast.info("User account is not linked to any Customer. Please contact admin!");
      setTimeout(() => {
        localStorage.removeItem("is_logged_in");
        navigate("/login", { replace: true });
        localStorage.clear();
      }, 10);
    }
  } , [])


  const {
    data: totalRequestsLastQuarter,
    isLoading: totalRequestsLastQuarterLoading,
  } = useGetTotalRequestsLastQuarter();

  const { data: taskLevelMetrics, isLoading: taskLevelMetricsLoading } =
    useGetTaskLevelMetrics();

  const {
    data: firstResponseTimeLastQuarter,
    isLoading: firstResponseTimeLastQuarterLoading,
  } = useGetFirstResponseTimeLastQuarter();

  const [year1, setYear1] = React.useState({ value: "2024" });
  const [city1, setCity1] = React.useState({ value: null });
  const [priority1, setPriority1] = React.useState({ value: null });
  const [key, setKey] = React.useState(0);

  // Increment the key to trigger re-rendering and re-execution of the hook
  const triggerHookAgain = () => {
    setKey((prevKey) => prevKey + 1);
  };

  // Call triggerHookAgain whenever year1 or city1 changes
  React.useEffect(() => {
    triggerHookAgain();
  }, [year1, city1, priority1]);

  const { data: hoursWorked, isLoading: hoursWorkedLoading } =
    useGetHoursWorkedStat(
      {
        year1: year1.value,
        city1: city1.value,
        priority1: priority1.value,
      },
      [key]
    );

  const [year2, setYear2] = React.useState({ value: "2024" });
  const [city2, setCity2] = React.useState({ value: null });
  const [priority2, setPriority2] = React.useState({ value: null });
  const [key2, setKey2] = React.useState(0);

  // Increment the key to trigger re-rendering and re-execution of the hook
  const triggerHookAgain2 = () => {
    setKey2((prevKey) => prevKey + 1);
  };

  // Call triggerHookAgain whenever year1 or city1 changes
  React.useEffect(() => {
    triggerHookAgain2();
  }, [year2, city2, priority2]);

  const { data: requestsStat, isLoading: requestsStatLoading } =
    useGetRequestsStat(
      {
        year2: year2.value,
        city2: city2.value,
        priority2: priority2.value,
      },
      [key2]
    );

  const [year3, setYear3] = React.useState({ value: "2024" });
  const [city3, setCity3] = React.useState({ value: null });
  const [priority3, setPriority3] = React.useState({ value: null });
  const [key3, setKey3] = React.useState(0);
  const [key4, setKey4] = React.useState(0);
  const [key5, setKey5] = React.useState(0);
  const [tab, setTab] = React.useState("confirmation-time");

  // Increment the key to trigger re-rendering and re-execution of the hook
  const triggerHookAgain3 = () => {
    console.log("hook called again");
    if (tab === "confirmation-time") setKey3((prevKey) => prevKey + 1);
    if (tab === "first-response") setKey4((prevKey) => prevKey + 1);
    if (tab === "osrt") setKey5((prevKey) => prevKey + 1);
  };

  // Call triggerHookAgain whenever year1 or city1 changes
  React.useEffect(() => {
    triggerHookAgain3();
  }, [year3, city3, priority3, tab]);

  const {
    data: getConfirmationTimeKPIs,
    isLoading: getConfirmationTimeKPIsLoading,
  } = useGetConfirmationTimeKPIs(
    {
      year3: year3.value,
      city3: city3.value,
      priority3: priority3.value,
    },
    [key3]
  );

  const { data: firstResponseTime, isLoading: firstResponseTimeLoading } =
    useGetFirstResponseTimeKPIs(
      {
        year3: year3.value,
        city3: city3.value,
        priority3: priority3.value,
      },
      [key4]
    );

  const { data: metKPI, isLoading: metKPILoading } = useGetMetKPIs(
    {
      year3: year3.value,
      city3: city3.value,
      priority3: priority3.value,
    },
    [key5]
  );

  const { data: cities, isLoading: citiesLoading } = useGetCities();

  const { data: fiscalYears, isLoading: fiscalYearsLoading } =
    useGetFiscalYears();
  const { data: outstandingRequests, isLoading: outstandingRequestsLoading } =
    useGetOutstandingRequestsLastQuarter();
  const { data: requestUrgency, isLoading: requestUrgencyLoading } =
    useGetRequestUrgency();
  // const { data: taskLevelMetrics, isLoading: taskLevelMetricsLoading } = useGetTaskLevelMetrics();

  const truncateText = (text) => {
    if (text.length > 10) {
      return text.substring(0, 15) + "...";
    } else {
      return text;
    }
  };

  const [visibleRows, setVisibleRows] = React.useState(5); // Initial number of visible rows

  const handleShowMore = () => {
    setVisibleRows((prevVisibleRows) => prevVisibleRows + 5); // Increase visible rows by 5
  };

  const convertDecimalToHoursMinutes = (decimalHours) => {
    // Calculate hours and minutes
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
  
    // Format hours and minutes
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}`;
  };


  return (
    <>
      <div className="container">
        <div className="pb-24px">
          <div className="h1 m-title m-title-2 main-dashboard-title">
            Dashboard
          </div>
          <div className="row g-2">
            <div className="col-sm-6 col-xl-3">
              <div style={{height: "100px"}}  className="dashboard-card d-flex justify-content-space-between flex-column">
                <div>
                  <div className="h1">
                    <h2 className="last-quarter-metrics">Total requests</h2>
                    {!totalRequestsLastQuarterLoading
                      ? totalRequestsLastQuarter ? totalRequestsLastQuarter : 0
                      : 0}
                  </div>
                  <div className="dashboard-subhead">This quarter</div>
                </div>
              </div>
            </div>
            <div style={{ flex: '0 0 22%', maxWidth: '22%' }} className="col-sm-6 col-xl-3">
              <div style={{height: "100px"}}  className="dashboard-card d-flex justify-content-center-space-between flex-column">
                <div>
                  <div className="h1">
                    <h2 className="last-quarter-metrics">
                      First response time
                    </h2>
                    {!firstResponseTimeLastQuarterLoading
                      ? (firstResponseTimeLastQuarter ? firstResponseTimeLastQuarter.toFixed(1) : 0.0)
                      : 0}
                  </div>
                  <div className="dashboard-subhead">
                    hours, average this quarter
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3">
              <div style={{height: "110px"}}  className="dashboard-card d-flex justify-content-center-space-between flex-column">
                <div>
                  <div className="h1">
                    <h2 className="last-quarter-metrics">
                      Outstanding requests
                    </h2>
                    {!outstandingRequestsLoading ? (outstandingRequests ? outstandingRequests :0) : 0}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ flex: '0 0 38%', maxWidth: '38%' }} className="col-sm-6 col-xl-3">
              <div className="dashboard-card d-flex justify-content-center-space-between flex-column">
                <div>
                  <div style={{marginBottom: "0"}}  className="h1">
                    <h2 className="last-quarter-metrics">Request urgency</h2>
                  </div>
                  <ApexPieChart
                    requestUrgency={
                      !requestUrgencyLoading ? requestUrgency : []
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          {/* Global Matrix */}
          <div className="dashboard-title">Global metrics</div>
          <div className="row chart">
            <div className="col-md-6 col-xxl-4">
              <div
                style={{ position: "relative" }}
                className="dashboard-card dashboard-card-chart pl-0"
              >
                <div className="dashboard-analytics-title">Hours</div>
                <div className="d-flex justify-content-between align-items-center gap-3 pl-3">
                  <SelectControl
                    current={year1}
                    update={setYear1}
                    data={
                      fiscalYearsLoading
                        ? [
                            {
                              value: "2024",
                              label: "2024",
                            },
                          ]
                        : fiscalYears
                    }
                  />
                  <SelectControl
                    current={city1}
                    update={setCity1}
                    data={
                      !citiesLoading
                        ? cities
                        : [
                            {
                              value: null,
                              label: "All cities",
                            },
                          ]
                    }
                  />
                  <SelectControl
                    current={priority1}
                    update={setPriority1}
                    data={selectData1}
                  />
                  <div className="select-arrow"></div>
                </div>
                <br />
                <ApexBarChart
                  hoursData={hoursWorkedLoading ? [] : hoursWorked}
                />
              </div>
            </div>
            <div className="col-md-6 col-xxl-4">
              <div
                style={{ position: "relative" }}
                className="dashboard-card dashboard-card-chart pl-0"
              >
                <div className="dashboard-analytics-title">Requests</div>
                <div
                  style={{ marginBottom: "38px" }}
                  className="d-flex justify-content-between align-items-center gap-3 pl-3"
                >
                  <SelectControl
                    current={year3}
                    update={setYear2}
                    data={
                      fiscalYearsLoading
                        ? [
                            {
                              value: "2024",
                              label: "2024",
                            },
                          ]
                        : fiscalYears
                    }
                  />
                  <SelectControl
                    current={city2}
                    update={setCity2}
                    data={
                      !citiesLoading
                        ? cities
                        : [
                            {
                              value: null,
                              label: "All cities",
                            },
                          ]
                    }
                  />
                  <SelectControl
                    current={priority2}
                    update={setPriority2}
                    data={selectData1}
                  />
                </div>
                <ApexBarChart2 data={requestsStatLoading ? [] : requestsStat} />
              </div>
            </div>
            <div className="col-md-6 col-xxl-4">
              <div 
                style={{ position: "relative" }}
               className="dashboard-card h-auto dashboard-card-chart pl-0">
                <div className="dashboard-analytics-title">KPIs</div>
                <div className="pl-3">
                  <div className="d-flex flex-wrap align-items-center gap-3 mb-10px">
                    {/* Buttons for switching tabs */}
                    <button
                      className={`kpi-btn ${
                        tab === "first-response" && "active"
                      }`}
                      type="button"
                      onClick={() => setTab("first-response")}
                    >
                      First response time
                    </button>
                    <button
                      className={`kpi-btn ${
                        tab === "confirmation-time" && "active"
                      }`}
                      type="button"
                      onClick={() => setTab("confirmation-time")}
                    >
                      Confirmation time
                    </button>
                    <button
                      className={`osrt-btn ${tab === "osrt" && "active"}`}
                      type="button"
                      onClick={() => setTab("osrt")}
                    >
                      Onsite response time
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-3 kpi-chart">
                  <SelectControl
                    current={year3}
                    update={setYear3}
                    data={
                      fiscalYearsLoading
                        ? [
                            {
                              value: "2024",
                              label: "2024",
                            },
                          ]
                        : fiscalYears
                    }
                  />
                  <SelectControl
                    current={city3}
                    update={setCity3}
                    data={
                      !citiesLoading
                        ? cities
                        : [
                            {
                              value: null,
                              label: "All cities",
                            },
                          ]
                    }
                  />
                  <SelectControl
                    current={priority3}
                    update={setPriority3}
                    data={selectData1}
                  />
                </div>
                {/* Place the ApexBarChart3 component here */}
                <ApexBarChart3
                  is_from_met ={tab == "osrt"}
                  data={
                    tab == "osrt"
                      ? metKPILoading
                        ? []
                        : metKPI
                      : tab == "first-response"
                      ? firstResponseTimeLoading
                        ? []
                        : firstResponseTime
                      : tab == "confirmation-time"
                      ? getConfirmationTimeKPIsLoading
                        ? []
                        : getConfirmationTimeKPIs
                      : []
                  }
                />
              </div>
            </div>
          </div>
          <br />
          <br />
          {/* Task Level Matrix */}
          <div className="dashboard-title">Task-level metrics</div>
          <div className="dashboard-card">
            <div className="table--responsive">
              <table className="__table">
                <tbody>
                  {(taskLevelMetricsLoading ? [] : taskLevelMetrics)
                    .slice(0, visibleRows)
                    .map((item, index) => (
                      <tr
                        onClick={(e) => {
                          navigate(`/requests/work-visit/${item[0]}`);
                        }}
                        style={{ cursor: "pointer" }}
                        key={index}
                      >
                        <td>
                          <div
                            className="id"
                            style={{ textAlign: "left", padding: "0 2px " }}
                          >
                            {item[0]}
                          </div>
                        </td>
                        <td>
                          <div className="name" style={{ textAlign: "left" }}>
                            {truncateText(item[1])}
                          </div>
                        </td>
                        <td>
                          <div className="city" style={{ textAlign: "left" }}>
                            <Pin /> <span>{item[2]}</span>
                          </div>
                        </td>
                        <td>
                          <div
                            className="response-time"
                            style={{ textAlign: "left" }}
                          >
                            <strong>{item[3] ? convertDecimalToHoursMinutes(item[3].toFixed(2)) : "0.00"}</strong> Hours worked
                          </div>
                        </td>
                        <td>
                          <div
                            className="response-time"
                            style={{ textAlign: "left" }}
                          >
                            Responded in{" "}
                            <strong>{item[4] ? item[4].toFixed(1) : "0.0"} hours</strong>
                          </div>
                        </td>
                        <td>
                          <div
                            className="response-time"
                            style={{ textAlign: "left" }}
                          >
                            Scheduled in <strong>{item[5] ? item[5].toFixed(1) : "0.0"} </strong>{" "}
                            hours
                          </div>
                        </td>
                        <td>
                          <div
                            className="response-time"
                            style={{ textAlign: "left" }}
                          >
                            Onsite in <strong>{item[7] ? item[7].toFixed(1) : "0.0"} </strong>days
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <br />
          {!taskLevelMetricsLoading ? (
            visibleRows < taskLevelMetrics.length && (
              <Link to="#" className="show-more" onClick={handleShowMore}>
                Show more
              </Link>
            )
          ) : (
            <></>
          )}
          <br />
          <br />
          <br />
        </div>
      </div>
    </>
  );
};

const selectData1 = [
  {
    value: null,
    label: "All priorities",
  },
  {
    value: "Standard",
    label: "Standard",
  },
  {
    value: "High",
    label: "High",
  },
  {
    value: "Very High",
    label: "Very High",
  },
  {
    value: "Emergency",
    label: "Emergency",
  },
];

const Pin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="20"
  >
    <path
      fillRule="evenodd"
      d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
      clipRule="evenodd"
    />
  </svg>
);
export default Dashboards;
