import React, { useState } from "react";
import Select from "react-select";

const SelectControl = ({ data, update, current }) => {
	const [selectedOption, setSelectedOption] = useState(data[0]);
	return (
		<>
			<Select
				defaultValue={selectedOption}
				onChange={update}
				options={data}
				isSearchable={false}
				className="form--select"
				classNamePrefix="form--select"
			/>
		</>
	);
};

export default SelectControl;
