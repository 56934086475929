import { toast } from 'react-toastify'

export const onErrorMutationCommon = (error) => {
  if (error.response?.status === 401) {
    console.log("error");
    toast.error('Unauthorized')
    localStorage.clear()
    // location.reload()
    return
  }
  // if (!authToken) {
  //   // location.reload()
  //   return
  // }

  // toast.error('Something went wrong')
}
