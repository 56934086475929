import React from "react";
import Assignment from "../Components/Requests/Assignment/Assignment";
import Description from "../Components/Requests/Description/Description";
import Details from "../Components/Requests/Details/Details";
import Quick from "../Components/Requests/Quick/Quick";
import Tracking from "../Components/Requests/Tracking/Tracking";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useGetRequestDetail, useGetTaskTimeWorked } from "../api/requests";

const Requests = () => {
  const { taskname } = useParams();
  const navigate = useNavigate();
  const { data: requestDetail, isLoading: requestDetailLoading } =
    useGetRequestDetail({name: taskname, navigate});

  const { data: taskTimeWorked, isLoading: taskTimeWorkedLoading } =
    useGetTaskTimeWorked(taskname);

  return (
    <>
      <div className="container">
        <div className="pb-24px">
          <Quick task={!requestDetailLoading ? requestDetail : {}} />
          <div className="grid-2 mt-24">
            <Assignment task={!requestDetailLoading ? requestDetail : {}} />
            <Details task={!requestDetailLoading ? requestDetail : {}} />
          </div>
          <div className="grid-2-wide mt-24">
            <Description task={!requestDetailLoading ? requestDetail : {}} />
            <Tracking task={!taskTimeWorkedLoading ? taskTimeWorked : []}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Requests;
