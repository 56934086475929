import { json } from "react-router-dom";
import axiosAxis, {BACKEND_URL} from "./axiosInstance";
import axios from "axios";

const countCategoriesInOrder = (data, order) => {
  const categoryCounts = {};

  // Initialize counts for each category to zero
  order.forEach((category) => {
    categoryCounts[category] = 0;
  });

  // Iterate through the data array and count the occurrences of each category
  data.forEach((item) => {
    const category = item.priority;
    if (categoryCounts.hasOwnProperty(category)) {
      categoryCounts[category]++;
    }
  });

  // Convert category counts to an array in the specified order
  return categoryCounts;
};

export const getTotalRequestsLastQuarter = async () => {
  try {
    const { data } = await axiosAxis.get(
      `api/resource/Task?filters=[["local_creation_time", "Timespan", "this quarter"], ["customer","=", "${localStorage.getItem(
        "company"
      )}" ]]&fields=["count(name)"]&limit_page_length=99999`
    );
    return data.data[0]["count(name)"];
  } catch (error) {
    throw error; // Rethrow the error to handle it upstream
  }
};

export const getFirstResponseTimeLastQuarter = async () => {
  const { data } = await axiosAxis.get(
    `api/resource/Task?filters=[["local_creation_time", "Timespan", "this quarter"], ["customer","=", "${localStorage.getItem(
      "company"
    )}" ]]\
    &fields=["avg(first_response_time_hours)"]&limit_page_length=9999999`
  );
  return data.data[0]["avg(first_response_time_hours)"];
};

export const getOutstandingRequestsLastQuarter = async () => {
  const { data } = await axiosAxis.get(
    `api/resource/Task?filters=[["customer","=", "${localStorage.getItem(
      "company"
    )}" ], ["status", "in", ["Pending", "Open", "In progress"]]]&fields=["count(name)"]&limit_page_length=9999`
  );
  return data.data[0]["count(name)"];
};

export const getRequestUrgency = async () => {
  // FIXME: group by
  const { data } = await axiosAxis.get(
    `api/resource/Task?filters=[["customer","=", "${localStorage.getItem(
      "company"
    )}" ], ["local_creation_time", "Timespan", "this quarter"], ["priority", "in", ["High", "Very high", "Emergency", "Standard"]]]&fields=["priority"]&limit_page_length=999999`
  );
  const order = ["Standard", "High", "Very High", "Emergency"];
  return countCategoriesInOrder(data.data, order);
};

export const getTaskLevelMetrics = async () => {
  const { data } = await axiosAxis.get(
    "api/method/optora.custom_scripts.task.task.task_level_metrics",
    { params: {company: localStorage.getItem("company")} }
  );

  if (localStorage.getItem("company") == null || localStorage.getItem("company") == undefined) {
    return []
  }

  return data.message;
};
export const getAllRequests = async (_data) => {
  const range = _data.dates[0];
  const order = _data.order;
  let formattedRange;

  if (range) {
    formattedRange = {
      startDate: range.startDate
        ? `${range.startDate.getFullYear()}-${(range.startDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${range.startDate
            .getDate()
            .toString()
            .padStart(2, "0")}`
        : null,
      endDate: range.endDate
        ? `${range.endDate.getFullYear()}-${(range.endDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${range.endDate
            .getDate()
            .toString()
            .padStart(2, "0")}`
        : null,
    };
  } else {
    formattedRange = { startDate: null, endDate: null };
  }

  const filters = [
    ["subject", "LIKE", `%${_data.search}%`],
    ["customer", "=", localStorage.getItem("company")],
  ];

  if (_data.requesters.length > 0)
    filters.push(["customer_email", "IN", _data.requesters]);
  // Check if cities array has elements before adding location filter
  if (_data.cities.length > 0) {
    const locationFilter = ["location", "in", _data.cities];
    filters.push(locationFilter);
  }

  if (_data.statuses.length > 0) {
    const statusFilter = ["status", "in", _data.statuses];
    filters.push(statusFilter);
  }

  // Check if startDate and endDate are not null before adding them to filters
  if (formattedRange.startDate == formattedRange.endDate)
  filters.push([
    "local_creation_time",
      "between",
    [formattedRange.startDate, formattedRange.endDate],
  ]);
  else {
    if (formattedRange.startDate) {
      filters.push([
        "local_creation_time",
        formattedRange.endDate ||
        !(formattedRange.endDate == formattedRange.startDate)
          ? ">="
          : "=",
        formattedRange.startDate,
      ]);
    }
  
    if (formattedRange.endDate) {
      filters.push([
        "local_creation_time",
        formattedRange.startDate ||
        !(formattedRange.endDate == formattedRange.startDate)
          ? "<="
          : "=",
        formattedRange.endDate,
      ]);
    }
  }

  const data = await axiosAxis.get(`api/resource/Task`, {
    params: {
      fields: JSON.stringify(["*"]),
      limit_page_length: 99999,
      filters: JSON.stringify(filters),
      order_by: `local_creation_time ${order}`,
    },
  });
  return data.data.data;
};

export const getTechnicianDetail = async (email) => {
  const { data } = await axiosAxis.get(
    `api/resource/User?filters=[["email", "=", "${email}"]]&fields=["*"]`
  );
  
  return data.data;
};

export const getRequestDetail = async (name) => {
  const { data } = await axiosAxis.get(
    `api/resource/Task/${name}?fields=["*"]&limit_page_length=10`
  );
  const detail = await getTechnicianDetail(data.data.confirmed_by);
  if (detail.length > 0) data.data.confirmed_by_phone = detail[0].phone;
  else data.data.confirmed_by_phone = "-";
  // FIXME: Get detail of a request
  return data.data;
};

export const getTaskTimeWorked = async (task) => {
  console.log("triggering");
  const { data } = await axiosAxis.get(
    `api/resource/Task%20Time%20Tracker?filters=[ ["task", "=", "${task}"]]&fields=["*"]&limit_page_length=999`
  );
  return data.data;
};

export const getCities = async (_data) => {
  const { data } = await axiosAxis.get(
    `api/method/optora.custom_scripts.task.task.city_list_by_company`,
    {
      params: {
        company: localStorage.getItem("company")
      }
    }
  );
  let cities = data.message.map((city) => ({ value: city[0], label: city[0] }));
  cities.unshift({
    value: null,
    label: "All cities",
  });

  return cities;
};

export const getFiscalYears = async () => {
  const { data } = await axiosAxis.get(
    `api/resource/Fiscal%20Year?fields=["*"]`
  );
  let years = data.data.map((year) => ({
    value: year.name,
    label: year.name,
  }));
  return years;
};

export const getHoursWorkedStat = async (_data) => {
  const { data } = await axiosAxis.post(
    `api/method/optora.custom_scripts.task.task.global_metrics`,
    { ..._data, company: localStorage.getItem("company") }
  );

  let cities = [];
  let checkCountries = (country) => {
    return cities.find((c) => c === country);
  };

  data.message.forEach((record) => {
    if (record[3] == undefined ||record[3] == null ) return
    if (checkCountries(record[0]) === undefined) cities.push(record[0]);
  });

  const countriesWithData = cities.map((cityName) => ({
    name: cityName,
    data: Array(12).fill(0),
  }));
  const cityData = countriesWithData;

  data.message.forEach((entry) => {
    const [city, date, _, value] = entry;
    if (date == undefined || date == null) return
    if (value == undefined || value == null) return
    let city2 = city;
    const month = date.split("-")[1];
    const monthIndex = parseInt(month, 10) - 1;
    let index = cityData.findIndex((city) => city.name == city2);
    cityData[index]["data"][monthIndex] = value;
  });
  console.log("******************", cityData)

  return cityData;
};

export const getRequestsStat = async (_data) => {
  const { data } = await axiosAxis.post(
    `api/method/optora.custom_scripts.task.task.requests_metric`,
    _data
  );

  let cities = [];
  let checkCountries = (country) => {
    return cities.find((c) => c === country);
  };

  data.message.forEach((record) => {
    console.log("lopping");
    if (checkCountries(record[0]) === undefined) cities.push(record[0]);
  });

  const countriesWithData = cities.map((cityName) => ({
    name: cityName,
    data: Array(12).fill(0),
  }));
  const cityData = countriesWithData;

  data.message.forEach((entry) => {
    const [city, date, value] = entry;
    let city2 = city;
    const month = date.split("-")[1];
    const monthIndex = parseInt(month, 10) - 1;
    let index = cityData.findIndex((city) => city.name == city2);
    cityData[index]["data"][monthIndex] = value;
  });

  return cityData;
};

export const getMetKPIs = async (_data) => {
  const { data } = await axiosAxis.post(
    `api/method/optora.custom_scripts.task.task.kpi_metrics`,
    _data
  );

  const kpi = [Array(12).fill(0), Array(12).fill(0)];
  data.message.forEach((entry) => {
    const [date, hasMet, noMet] = entry;

    const month = date.split("-")[1];
    const monthIndex = parseInt(month, 10) - 1;

    kpi[0][monthIndex] = hasMet.toFixed(1);
    kpi[1][monthIndex] = noMet.toFixed(1);
  });

  return [
    { name: "Met", data: kpi[0] },
    { name: "Not Met", data: kpi[1] },
  ];
};

export const getFirstResponseTimeKPIs = async (_data) => {
  console.log(_data);
  const { data } = await axiosAxis.post(
    `api/method/optora.custom_scripts.task.task.kpi_metrics`,
    { ..._data, chart_type: "first_response_time" }
  );

  const kpi = Array(12).fill(0);
  data.message.forEach((entry) => {
    const [date, firstResponseTime] = entry;
    const month = date.split("-")[1];
    const monthIndex = parseInt(month, 10) - 1;

    kpi[monthIndex] = firstResponseTime;
    console.log(firstResponseTime);
  });

  console.log(kpi);
  return [{ name: "First Response Time", data: kpi.map(d => d.toFixed(1)) }];
};

export const getConfirmationTimeKPIs = async (_data) => {
  const { data } = await axiosAxis.post(
    `api/method/optora.custom_scripts.task.task.kpi_metrics`,
    { ..._data, chart_type: "confirmation_time" }
  );

  const kpi = Array(12).fill(0);
  data.message.forEach((entry) => {
    const [date, firstResponseTime] = entry;
    const month = date.split("-")[1];
    const monthIndex = parseInt(month, 10) - 1;

    kpi[monthIndex] = firstResponseTime;
    console.log(firstResponseTime);
  });

  console.log(kpi);
  return [{ name: "Confirmation Time", data: kpi.map(k => k.toFixed(1)) }];
};

export const getLogin = async (creds) => {
  const response = await axiosAxis.post("api/method/login", creds);

  return response.data;
};

export const getCompanyDetail = async (name) => {
  const { data } = await axiosAxis.get(
    `/api/method/frappe.desk.form.load.getdoc`,
    {
      params: {
        doctype: "Contact",
        name,
        fields: JSON.stringify(["*"]),
      },
    }
  );
  console.log("------------------------------");
  console.log(data.docs[0].links[0].link_name);
  console.log("------------------------------");
  return data.docs[0].links[0].link_name;
};

export const getCompanyName = async (email) => {
  const { data } = await axiosAxis.get(
    `api/resource/Contact?filters=[["user", "=", "${email}"]]&fields=["*"]`
  );
  console.log("======================");
  console.log(data.data);
  console.log("======================");
  return await getCompanyDetail(data.data[0].name);
};

export const verifyOTP = async (otp) => {
  try {
    const response = await axiosAxis.post("/", otp);
    if (response.status == 200) {
      localStorage.setItem("is_logged_in", true);
      localStorage.setItem("email", otp.email);
      localStorage.setItem("company", await getCompanyName(otp.email));
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getLoggedInUser = async (email) => {
  const { data } = await axiosAxis.post(
    `api/method/optora.custom_scripts.task.task.user_detail_by_email?email=${email}`
  );
  return data.message[0];
};

export const getLoggedInUserCompany = async (email) => {
  const { data } = await axiosAxis.post(
    "api/method/optora.custom_scripts.task.task.user_company"
  );
  return data.data;
};

export const passwordReset = async (email) => {
  try {
    const _axios = axios.create({
      baseURL: BACKEND_URL,
    });
  
    const response = await _axios.get(
      `/api/method/frappe.core.doctype.user.user.reset_password?user=${email.email}`
    );
  
    if (response.status == 200) {
      email.toast.info("Password reset email has been sent!");
      return response.data;
    } else {
      email.toast.error("Unauthorized");
      return {};
    }
  } catch(err) {
    if (err.response.status == 401) {
      email.toast.error("Unauthorized");
    }
    if (err.response.status == 404) {
      email.toast.error("Email not found");
    }

    return {};
  }
};

export const getCustomerContacts = async () => {
  const { data } = await axiosAxis.get(
    `https://axis.optora.com/api/method/frappe.desk.form.load.getdoc?doctype=Customer&name=${localStorage.getItem(
      "company"
    )}`
  );

  const customerWithEmail = data.docs[0].__onload.contact_list.filter(
    (e) => e.email_ids.length > 0
  );
  return customerWithEmail;
};



export const resetPassword = async (resetPayload) => {
  const axiosResetInstance = axios.create({
    baseURL: BACKEND_URL,
  });
  try {
    const response = await axiosResetInstance.post("/", resetPayload);
    return response.status;
    
  } catch(err) {
    return err.response.status;
  }
};