import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Breadcrumbs from "./Components/Breadcrumbs/BreadCrumbs"; // Adjust the path as needed
import Sidebar from "./Components/SideBar/Sidebar";
import Topbar from "./Components/Topbar/Topbar";
import Dashboards from "./Pages/Dashboards";
import Invoices from "./Pages/Invoices";
import Login from "./Pages/Login";
import PasswordReset from "./Pages/PasswordReset";
import Requests from "./Pages/Requests";
import WorkVisitRequests from "./Pages/WorkVisitRequests";
import { QueryClientProvider } from "@tanstack/react-query";
import { useReactQuery } from "./hooks/useReactQuery";
import PrivateRoutes from "./privateRoutes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  const [isSidebarActive, setSidebarActive] = useState(false);
  const queryClient = useReactQuery();

  const toggleSidebar = () => {
    setSidebarActive(!isSidebarActive);
  };
  const closeSidebar = () => {
    setSidebarActive(false);
  };
  
  const checkSessionExpiration = () => {
    const storedTimestamp = localStorage.getItem('timestamp');
      if (storedTimestamp) {
          const storedDate = new Date(parseInt(storedTimestamp, 10));
          const currentDate = new Date();
          const differenceInMilliseconds = currentDate - storedDate;
          const differenceInMonths = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 30);
      
          if (differenceInMonths >= 2) {
              localStorage.clear()
          } 
      }
    }

  checkSessionExpiration()
    
  return (
    <div className={`wrapper-app ${isSidebarActive ? "sidebar-active" : ""}`}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ToastContainer/>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route
                path="/"
                element={
                  <>
                    <Sidebar
                      isActive={isSidebarActive}
                      closeSidebar={closeSidebar}
                    />
                    <div className="main-contents">
                      <Topbar onToggleSidebar={toggleSidebar} />
                      <Breadcrumbs /> {/* Render the Breadcrumbs component */}
                      <Dashboards />
                    </div>
                  </>
                }
              />
              <Route
                path="/requests"
                element={
                  <>
                    <Sidebar
                      isActive={isSidebarActive}
                      closeSidebar={closeSidebar}
                    />
                    <div className="main-contents">
                      <Topbar onToggleSidebar={toggleSidebar} />
                      <Breadcrumbs /> {/* Render the Breadcrumbs component */}
                      <WorkVisitRequests />
                    </div>
                  </>
                }
              />
              <Route
                path="/requests/work-visit/:taskname"
                element={
                  <>
                    <Sidebar
                      isActive={isSidebarActive}
                      closeSidebar={closeSidebar}
                    />
                    <div className="main-contents">
                      <Topbar onToggleSidebar={toggleSidebar} />
                      <Breadcrumbs /> {/* Render the Breadcrumbs component */}
                      <Requests />
                    </div>
                  </>
                }
              />

              <Route
                path="/invoices-statements"
                element={
                  <>
                    <Sidebar
                      isActive={isSidebarActive}
                      closeSidebar={closeSidebar}
                    />
                    <div className="main-contents">
                      <Topbar onToggleSidebar={toggleSidebar} />
                      <Breadcrumbs /> {/* Render the Breadcrumbs component */}
                      <Invoices />
                    </div>
                  </>
                }
              />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/password-reset/:token" element={<PasswordReset />} />
          </Routes>
        </QueryClientProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
