import React from "react";
import "./Assignment.scss";
import { MdEmail } from "react-icons/md";
import { GiSmartphone } from "react-icons/gi";
import { BsFillPersonVcardFill } from "react-icons/bs";

const Assignment = (props) => {
  console.log(props.task)
  return (
    <div className="box-bg assignment-content">
      <div className="h3 fw-semibold">Technician Assignment</div>
      <div className="assignment-user">
        <ul style={{marginLeft: "0px"}} className="list">
          <li >
            <span>
              <BsFillPersonVcardFill />
            </span>{" "}
            {props.task ? props.task.confirmed_name : ""}
          </li>
          <li>
            <span>
              <GiSmartphone />
            </span>{" "}
            {props.task.confirmed_by_phone}
          </li>
        </ul>
      </div>

      {props.task && props.task.extra_technician ? (
        <>
          <div className="h3 fw-semibold">Additional assigned technicians</div>
          <div className="technicians">
            {props.task.extra_technician.map((technician) => {
              return <span>{technician.technician_name}</span>;
            })}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Assignment;
