import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Modal from "react-modal";
import OtpInput from "react-otp-input";
import {
  useGetLogin,
  useVerifyOTP,
  useGetCompanyName,
  usePasswordReset,
} from "../api/requests";
import { toast } from "react-toastify";
import { useEffect } from "react";

const Login = () => {
  const [otp, setOtp] = useState("");
  const [loginResponse, setLoginResponse] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { mutateAsync: verify } = useVerifyOTP();

  let subtitle;
  const customStyles = {
    content: {
      top: "20%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      zIndex: 9999, // Set your desired z-index value here
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "#fff",
    },
    
  };

  const authenticate = (e) => {
    console.log("authenticating");
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [resetModalIsOpen, setResetModalIsOpen] = React.useState(false);

  function openModal(e) {
    setIsOpen(true);
  }

  function afterOpenModal(e) {
    // references are now sync'd and can be accessed.
  }

  function closeModal(e) {
    setIsOpen(false);
  }

  const { mutateAsync: mutate, isPending } = useGetLogin();

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    try {
      console.log("mutation called");
      // Call the mutation function passing the credentials
      const _loginResponse = await mutate({
        usr: email,
        pwd: `${password}`,
      });

      if (_loginResponse.tmp_id != undefined) {
        toast.info("Verification code was sent to your e-mail.");
      }
      console.log("Login _loginResponse:", _loginResponse);
      setLoginResponse(_loginResponse);
      console.log("mutation called again");

      openModal(e); // Open modal if login was successful
    } catch (error) {
      console.error("Login failed:", error);
      // Handle login error, if any
    }
  };

  const navigate = useNavigate();

  const handleVerify = async (e) => {
    console.log({
      cmd: "login",
      tmp_id: loginResponse.tmp_id,
      otp,
    });
    await verify({
      cmd: "login",
      tmp_id: loginResponse.tmp_id,
      otp,
      email,
    });
    
    closeModal(e);
    navigate("/");

  };

  const { mutateAsync: resetPassword } = usePasswordReset();

  const [resetEmail, setResetEmail] = useState("");
  function closeModalResetPassword(e) {
    setResetModalIsOpen(false);
  }

  const resetPasswordAction = async (e) => {
    e.preventDefault();
      closeModalResetPassword();
      await resetPassword({ email: resetEmail, toast });
  };

  function openResetPasswordModal(e) {
    setResetModalIsOpen(true);
  }

  return (
    <>
      <section
        className="login-section"
        style={{
          background: `url('/images/login-bg.png') no-repeat center center / cover`,
        }}
      >
        <div className="login-slider-area">
          <div className="logo">
            <Link to="/">
              <img
                className="logo-black"
                src="/images/logo-black.svg"
                width={121}
                alt=""
              />
              <img
                className="logo-white"
                src="/images/logo-white.svg"
                width={121}
                alt=""
              />
            </Link>
          </div>
          <div className="my-auto pb-5">
            <Swiper
              slidesPerView={1}
              spaceBetween={100}
              pagination={{
                clickable: true,
                className: "slider-pagination",
              }}
              modules={[Pagination, Autoplay]}
              autoplay={{
                delay: 5000,
              }}
            >
              <SwiperSlide>
                <div className="slider-item">
                  <h6 className="title">
                    Explore historic tasks seamlessly, understanding its
                    details, contributors and tracking time with precision.
                  </h6>
                  <p>We enjoy details.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slider-item">
                  <h6 className="title">
                    Gauge performance in a clear way, comprehending workload
                    variations and efficiency across different locations.
                  </h6>
                  <p>We prioritize performance.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slider-item">
                  <h6 className="title">
                    Get clarity on invoice history, effortlessly accessing past
                    invoices and structured statements of work.
                  </h6>
                  <p>We bring clarity.</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div
          className="login-form-area"
          style={{
            background: `url('/images/login-bg.png') no-repeat center center / cover`,
          }}
        >
          <div className="logo d-md-none w-100">
            <Link to="/">
              <img
                className="logo-white"
                src="/images/logo-white.svg"
                width={121}
                alt=""
              />
            </Link>
          </div>
          <form className="login-form">
            <h5 className="title">Login to your account</h5>
            <div className="form-group">
              <label className="form--label">Email:</label>
              <input
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="form-control form--control"
              />
            </div>
            <div className="form-group mb-29">
              <label className="form--label">Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className="form-control form--control"
              />
            </div>
            <div className="form-group">
              <button onClick={handleSubmit} disabled={isPending} className="btn">
                Login
              </button>
            </div>
            <Link
              onClick={(e) => {
                openResetPasswordModal();
              }}
              to="#"
            >
              Forgot password
            </Link>
          </form>
        </div>

        <div style={{ zIndex: "999999" }}>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Verify"
          >
            <div
              style={{
                height: "120px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="container"
            >
              <h3 style={{ textAlign: "center", marginBottom: "10px" }}>
                Verify
              </h3>
              <div className="container">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  maxLength={20}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>

              <br />

              <div style={{width: "100%"}}>
                <button
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    fontSize: "16px",
                    "height": "34.441px",
                    borderRadius: "5px",
                    "background": "#0089eb",
                    flexShrink: 0,
                    boxShadow: "none",
                    outline: "none",
                    border: "none",
                    color: "#fff",
                    cursor: "pointer"
                  }}
                  onClick={handleVerify}
                >
                  verify
                </button>
                {/* <button style={{ width: "100%" }} onClick={closeModal}>
                  close
                </button> */}
              </div>
            </div>
          </Modal>
        </div>

        <div style={{ zIndex: "999999" }}>
          <Modal
            isOpen={resetModalIsOpen}
            onRequestClose={closeModalResetPassword}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Verify"
          >
            <div
              style={{
                height: "120px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="login-form"
            >
              <h3 style={{ textAlign: "center", marginBottom: "10px" }}>
                Reset Password
              </h3>
              <form onSubmit={resetPasswordAction}>
              <input
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  borderRadius: "4px",
                  outline: "none",
                  width: "300px",
                }}
                typeEmail
                required
                placeholder="Email"
                value={resetEmail}
                onChange={(e) => {
                  setResetEmail(e.target.value);
                }}
                type="email"
                name=""
                id=""
              />

              <br />
              <br />

              <div style={{width: "100%"}} className="form-group">
                <button
                   style={{
                    width: "100%",
                    marginBottom: "10px",
                    fontSize: "16px",
                    "height": "34.441px",
                    borderRadius: "5px",
                    "background": "#0089eb",
                    flexShrink: 0,
                    boxShadow: "none",
                    outline: "none",
                    border: "none",
                    color: "#fff",
                    cursor: "pointer"
                  }}
                  type="submit"
                >
                  Click here to reset password
                </button>
              </div>

              </form>
              
            </div>
          </Modal>
        </div>
      </section>
    </>
  );
};

export default Login;
