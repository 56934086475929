import React, { useEffect, useRef, useState } from "react";
import "./WorkVisit.scss";
import {
  IoSearch,
  IoFilterSharp,
  IoFunnelOutline,
  IoLocationSharp,
} from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import { TbSquareRounded } from "react-icons/tb";
import { MdDateRange } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { addDays, setDate } from "date-fns";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { toast } from "react-toastify";

import {
  useGetAllRequests,
  useGetCities,
  useGetCustomerContacts,
} from "../../../api/requests";

const WorkVisit = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("is_logged_in") === "true" && localStorage.getItem("company") === null || localStorage.getItem("company") === undefined) {
      toast.info("User account is not linked to any Customer. Please contact admin!");
      setTimeout(() => {
        localStorage.removeItem("is_logged_in");
        navigate("/login", { replace: true });
        localStorage.clear();
      }, 10);
    }
  } , [])

  const { data: cities, isLoading: citiesLoading } = useGetCities({});
  const { data: requesters, isLoading: requesterLoading } =
    useGetCustomerContacts();
  const [dates, setDates] = useState([null, null]);
  const [search, setSearch] = useState("");
  const [isDateRangePickerVisible, setIsDateRangePickerVisible] =
    useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isRequesterContentVisible, setIsRequesterContentVisible] =
    useState(false);
  const [isDateContentVisible, setIsDateContentVisible] = useState(false);
  const [isStatusContentVisible, setIsStatusContentVisible] = useState(false);
  const [isLocationContentVisible, setIsLocationContentVisible] =
    useState(false);
  const [isRequesterActive, setIsRequesterActive] = useState(false);
  const [isSortDropDownVisible, setIsSortDropDownVisible] = useState(false);

  const filterRef = useRef(null);
  const sortRef = useRef(null);

  const statuses = [
    {
      name: "Open",
      value: "Open",
    },
    {
      name: "Pending",
      value: "Pending",
    },
    {
      name: "In progress",
      value: "In progress",
    },
    {
      name: "Completed",
      value: "Completed",
    },
    {
      name: "Cancelled",
      value: "Cancelled",
    },
  ];

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
    setIsRequesterContentVisible(false);
    setIsDateContentVisible(false);
    setIsStatusContentVisible(false);
    setIsLocationContentVisible(false);
    setIsSortDropDownVisible(false);
  };

  const toggleSortDropdown = () => {
    setIsSortDropDownVisible(!isSortDropDownVisible);
    setIsDropdownVisible(false);
    setIsRequesterContentVisible(false);
    setIsDateContentVisible(false);
    setIsStatusContentVisible(false);
    setIsLocationContentVisible(false);
  };

  const toggleRequesterContent = () => {
    setIsRequesterContentVisible(!isRequesterContentVisible);
    setIsDateContentVisible(false);
    setIsStatusContentVisible(false);
    setIsRequesterActive(!isRequesterActive);
    setIsLocationContentVisible(false);
    setIsSortDropDownVisible(false);
  };

  const toggleDateContent = () => {
    setIsDateContentVisible(!isDateContentVisible);
    setIsRequesterContentVisible(false);
    setIsStatusContentVisible(false);
    setIsLocationContentVisible(false);
    setIsSortDropDownVisible(false);
  };

  const toggleStatusContent = () => {
    setIsStatusContentVisible(!isStatusContentVisible);
    setIsRequesterContentVisible(false);
    setIsDateContentVisible(false);
    setIsSortDropDownVisible(false);
    setIsLocationContentVisible(false);
  };

  const toggleLocationContent = () => {
    setIsLocationContentVisible(!isLocationContentVisible);
    setIsStatusContentVisible(false);
    setIsRequesterContentVisible(false);
    setIsSortDropDownVisible(false);
    setIsDateContentVisible(false);
  };
  const handleClickOutside = (e) => {
    if (filterRef.current && !filterRef.current.contains(e.target)) {
      setIsDropdownVisible(false);
      setIsRequesterContentVisible(false);
      setIsDateContentVisible(false);
      setIsStatusContentVisible(false);
      setIsSortDropDownVisible(false);
      setIsRequesterActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [state, setState] = useState([
    {
      startDate: addDays(new Date(), -30), // 30 days before today
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const toggleDateRangePicker = () => {
    setIsDateRangePickerVisible(!isDateRangePickerVisible);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0"); // Add leading zero to day
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero to month
    const year = date.getFullYear().toString().slice(-2); // Extract last two digits of the year
  
    return `${day}-${month}-${year}`;
  };

  const getDaysAgo = (dateString) => {
    const currentDate = new Date();
    const date = new Date(dateString);
    const timeDifference = currentDate.getTime() - date.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    if (daysDifference === 0) {
      return "Today";
    } else if (daysDifference === 1) {
      return "1 day ago";
    } else {
      return `${daysDifference} days ago`;
    }
  };

  const [citiesFilter, selectCitiesFilter] = useState([]);
  const [citiesValue, selectCitiesValue] = useState([]);
  const [searchValue, selectSearchValue] = useState("");
  const [priority, setPriority] = useState([]);
  const [priorityFilter, setPriorityFilter] = useState([]);
  // const [orderFilter, setOrderFilter] = useState("asc");
  const [order, setOrder] = useState("desc");

  const searchRequest = (e) => {
    setIsDropdownVisible(false);
    setIsRequesterContentVisible(false);
    setIsDateContentVisible(false);
    setIsStatusContentVisible(false);
    setIsLocationContentVisible(false);
    setIsDateRangePickerVisible(false);
    selectCitiesValue(citiesFilter);
    selectSearchValue(search);
    setPriorityFilter(priority);
    // setOrderFilter(order);
    setDates(state);

    console.log(citiesFilter);
  };

  const truncateText = (text) => {
    if (text.length > 15) {
      return text.substring(0, 15) + "...";
    } else {
      return text;
    }
  };

  // const handleCityCheckboxChange = (event) => {
  //   const { checked, value } = event.target;
  //   if (checked) {
  //     // Add the checked city value to the citiesFilter array
  //     selectCitiesFilter((prevCitiesFilter) => [...prevCitiesFilter, value]);
  //     console.log("City checked:", value);
  //     // You can store it in state or perform any other action as needed
  //   } else {
  //     // Remove the unchecked city value from the citiesFilter array
  //     selectCitiesFilter((prevCitiesFilter) =>
  //       citiesFilter.filter((city) => city !== value)
  //     );
  //     console.log("City unchecked:", value);
  //     // You can handle it as needed
  //   }
  // };

  // const handlePriorityCheckboxChange = (event) => {
  //   const { checked, value } = event.target;
  //   if (checked) {
  //     // Add the checked city value to the citiesFilter array
  //     setPriority((prevCitiesFilter) => [...priority, value]);
  //     console.log("prevCitiesFilter checked:", value);
  //     // You can store it in state or perform any other action as needed
  //   } else {
  //     // Remove the unchecked city value from the citiesFilter array
  //     setPriority((priority) => priority.filter((city) => city !== value));
  //     console.log("prevCitiesFilter unchecked:", value);
  //     // You can handle it as needed
  //   }
  // };


  // -----------------------------

  const [checkedRequesters, setCheckedRequesters] = useState([]);

  const toggleCheckbox = (emailId) => {
    if (checkedRequesters.includes(emailId)) {
      // If the emailId is already in the checkedRequesters array, remove it
      setCheckedRequesters(checkedRequesters.filter((id) => id !== emailId));
    } else {
      // Otherwise, add it to the checkedRequesters array
      setCheckedRequesters([...checkedRequesters, emailId]);
    }
  };

  const [checkedPriorities, setCheckedPriorities] = useState([]);

  const handlePriorityCheckboxChange = (event, index) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      // If the checkbox is checked, add its value to the checkedPriorities array
      setCheckedPriorities([...checkedPriorities, event.target.value]);
    } else {
      // If the checkbox is unchecked, remove its value from the checkedPriorities array
      setCheckedPriorities(checkedPriorities.filter((priority) => priority !== event.target.value));
    }
  };


  const [checkedCities, setCheckedCities] = useState([]);

  const handleCityCheckboxChange = (event, index) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setCheckedCities([...checkedCities, event.target.value]);
    } else {
      setCheckedCities(checkedCities.filter((city) => city !== event.target.value));
    }
  };



  const { data: allRequests, isLoading: allRequestsLoading } =
    useGetAllRequests({
      cities: checkedCities,
      search: searchValue,
      dates: state,
      statuses: checkedPriorities,
      order,
      requesters: checkedRequesters
    });

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      searchRequest(event);
    }
  };


  return (
    <div className="work-visit-content">
      <div className="work-head">
        <div>
          <div className="h1">Requests</div>
          <div>
            <div 
             className="filtering">
              <div className="search">
                <input
                  onKeyPress={handleKeyPress}
                  style={{
                    border: "1px solid #ccc",
                    padding: "8px",
                    borderRadius: "4px",
                    outline: "none",
                    width: "300px",
                  }}
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  type="text"
                  name=""
                  id=""
                />
                {/* <button>
                  <IoSearch /> Search
                </button> */}
              </div>
              <div className="filter" ref={filterRef}>
                <button onClick={toggleDropdown}>
                  <IoFunnelOutline /> Filter
                </button>
                {isDropdownVisible && (
                  <>
                    <ul className="filter-dropdown">
                      <li
                        className={`${isRequesterActive ? "active" : ""}`}
                        onClick={toggleRequesterContent}
                      >
                        <AiOutlineUser /> Requester
                      </li>
                      <li
                        className={`${isRequesterActive ? "active" : ""}`}
                        onClick={toggleStatusContent}
                      >
                        <TbSquareRounded /> Status
                      </li>
                      <li
                        className={`${isRequesterActive ? "active" : ""}`}
                        onClick={toggleLocationContent}
                      >
                        <IoLocationSharp /> Location
                      </li>
                    </ul>
                  </>
                )}

                {isRequesterContentVisible && (
                  <div className="requester-content">
                    {requesterLoading
                      ? []
                      : requesters.map((requester) => (
                          <div className="form-check">
                            <input
                              checked={checkedRequesters.includes(
                                requester.email_ids[0].email_id
                              )}
                              onChange={() =>
                                toggleCheckbox(requester.email_ids[0].email_id)
                              }
                              type="checkbox"
                              id="david_check"
                            />
                            <label
                              className="form-check-label"
                              style={{ fontSize: "10px" }}
                              htmlFor="david_check"
                            >
                              {requester.email_ids[0].email_id}
                            </label>
                          </div>
                        ))}
                  </div>
                )}

                {isDateContentVisible && (
                  <div className="date-content">
                    <div className="date-item">
                      <span>Creation date</span>
                      <input type="date" name="creation" />
                    </div>
                    <div className="date-item">
                      <span>Start date</span>
                      <input type="date" name="creation" />
                    </div>
                    <div className="date-item">
                      <span>Completion date</span>
                      <input type="date" name="creation" />
                    </div>
                  </div>
                )}

                {isLocationContentVisible &&
                  (!citiesLoading ? (
                    <>
                      <div className="status-content">
                        {cities.map((city, index) => {
                          if (city.value == undefined) return <></>;
                          return (
                            <div className="form-check">
                              <input
                                id={`city_${index}`}
                                value={city.value}
                                type="checkbox"
                                checked={checkedCities.includes(city.value)}
                                onChange={(event) => handleCityCheckboxChange(event, index)}
                              />
                              <label
                                className="form-check-label"
                                for="scheduled_check"
                                htmlFor={`city_${index}`}
                              >
                                <span className="Scheduled">{city.value}</span>
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    []
                  ))}

                {isStatusContentVisible && (
                  <div className="status-content">
                    {statuses.map((priority, index) => (
                      <div className="form-check" key={`priority_${index}`}>
                        <input
                          id={`priority_${index}`}
                          value={priority.value}
                          type="checkbox"
                          checked={checkedPriorities.includes(priority.value)}
                          onChange={(event) =>
                            handlePriorityCheckboxChange(event, index)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`priority_${index}`}
                        >
                          <span>{priority.value}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                )}

                {/* {isStatusContentVisible && (
                  <div className="status-content">
                    {statuses.map((status) => {
                      return (
                        <div className="form-check">
                          <input type="checkbox" id="scheduled_check" />
                          <label
                            className="form-check-label"
                            for="scheduled_check"
                          >
                            <span className="Scheduled">{status.value}</span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                )} */}
              </div>
              <div className="datepicker">
                <button onClick={toggleDateRangePicker}>
                  <MdDateRange />
                  Select date
                </button>
              </div>

              <div
                className="sort filter"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    marginRight: "10px",
                    fontSize: "15px",
                    black: "#000d39",
                    // fontWeight: "100",
                  }}
                >
                  Oldest
                </span>
                <label
                  onChange={(e) => {
                    setOrder(e.target.checked ? "desc" : "asc");
                  }}
                  class="switch"
                >
                  <input checked={order == "desc"? true : false} type="checkbox" />
                  <span class="slider"></span>
                </label>
                <span
                  style={{
                    fontSize: "15px",
                    black: "#000d39",
                    display: "inline-block",
                    marginLeft: "10px",
                  }}
                >
                  Newest
                </span>
              </div>

            </div>
          </div>
        </div>

        <div className="mb-auto"> 
          <a className="btn-lg-a" style={{padding: "auto"}} target="_blank" href = {`https://axis.optora.com/schedule?new=1&customer_email=${localStorage.getItem("email")}`}>+ New request</a>
        </div>
      </div>

      {isDateRangePickerVisible && (
        <DateRangePicker
          onChange={(item) => {
            console.log(item.selection);
            setState([item.selection]);
          }}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
          appendToBody={true}
        />
      )}

      <div className="work-body">
        {!allRequestsLoading && allRequests !== undefined ? (
          allRequests.length === 0 ? (
            <h3 style={{ textAlign: "center", marginTop: "10px" }}>
              No Results
            </h3>
          ) : null
        ) : null}

        {(!allRequestsLoading && allRequests != undefined
          ? allRequests
          : []
        ).map((item) => (
          <Link
            to={`/requests/work-visit/${item.name}`}
            className="work-item"
            key={item.name}
          >
            <div className="work-title">
              <div className="h3 title">
                <span>
                  {truncateText(item.subject)}
                  <span className="colored">{item.name}</span>
                </span>
              </div>
              <p className="place">
                <IoLocationSharp /> {item.location} •{" "}
                {formatDate(item.creation)}
              </p>
              {item.priority !== "Standard" && (
                <button className="emergency">{item.priority}</button>
              )}
            </div>
            <div className="work-date">
              Submitted {getDaysAgo(item.creation)}
            </div>
            <div className={`work-complete ${item.status}`}>{item.status}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default WorkVisit;
