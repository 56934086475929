import React from "react";  

const Invoices = () => {
  return (
    <> 
      <div className="container"></div>
    </>
  );
};

export default Invoices;
