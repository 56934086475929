import React, { useState, useEffect } from "react";
import { BiSolidDashboard, BiSolidUserCircle } from "react-icons/bi";
import { BsFillBoxSeamFill } from "react-icons/bs";
import { MdLogout } from "react-icons/md";
import { PiNotepadLight } from "react-icons/pi";
import { Link, NavLink } from "react-router-dom";
import "./Sidebar.scss";
import { useGetLoggedInUser, useGetCompanyName } from "../../api/requests";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ isActive, closeSidebar }) => {


  const [loggedInUser, setLoggedInUser] = useState(null);
  const { data: user, isLoading: userLoading } = useGetLoggedInUser(
    localStorage.getItem("email")
  );
  const navigate = useNavigate();

  // useEffect to update loggedInUser state when user data changes
  useEffect(() => {
    // Check if user data is available and set loggedInUser state
    if (user) {
      setLoggedInUser(user);
    }
  }, [user]);

  return (
    <div className="sidebar-main">
      <div className="menu-link">
        <div className="logo">
          <Link  style={{display: "flex",width: "100%", justifyContent: "space-between", alignItems: "flex-start" }} to="/">
            <div>
            <div >
              <h6>{localStorage.getItem("company") }</h6>
              <span className="slogan">
                {" "}
                {userLoading ? "" : user.full_name}
              </span>
            </div>

            </div>
            <div style={{paddingLeft: 2}}>
              <img style={{height: 20}} src="/beta.svg" alt="" />
            </div>
          </Link>
        </div>
        <div className="h6">MENU</div>
        <div className="nav">
          <NavLink to="/" onClick={closeSidebar}>
            <BiSolidDashboard /> Dashboards
          </NavLink>
          <NavLink to="/requests" onClick={closeSidebar}>
            <BsFillBoxSeamFill /> Requests
          </NavLink>
        </div>
      </div>
      <div className="sidebar-footer">
        <Link to="">
          <BiSolidUserCircle /> My account
        </Link>
        <a style={{cursor: "pointer"}} onClick={(e) => {
          navigate("/login")
          localStorage.clear()
        }}>
          <MdLogout /> Logout
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
