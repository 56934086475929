import axios from "axios";
export const BACKEND_URL = "https://axis.optora.com/";
const axiosAxis = axios.create({
  // withCredentials: true,
  baseURL: BACKEND_URL,
});

axiosAxis.interceptors.request.use((config) => {
  config.headers["Authorization"] = `token d17df398901550f:1aec8d6db68bd1a`;
  config.headers["Accept"] = "application/json";
  config.headers["sid"] = localStorage.getItem("sid");
  config.headers["Content-Type"] = "application/json";
  return config;
});

export default axiosAxis;
