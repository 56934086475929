import React from "react";
import './Topbar.scss'
import { FaBars } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Topbar = ({ onToggleSidebar }) => {
  return (
    <div className="top-bar">
      <div className="top-logo">
        <Link to="/">
          <img src="/images/logo-white.svg" alt="logo" height={40} />
        </Link>
      </div>
      <button onClick={onToggleSidebar}>
        <FaBars />
      </button>
    </div>
  );
};

export default Topbar;
