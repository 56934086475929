import { QueryCache, QueryClient } from '@tanstack/react-query'

import { onErrorMutationCommon } from './utils/onErrorMutationCommon'
import { onErrorQueryCommon } from './utils/onErrorQueryCommon'

export const useReactQuery = () => {
  return new QueryClient({
    defaultOptions: {
      mutations: {
        onError: onErrorMutationCommon,
      },
    },
    queryCache: new QueryCache({
      onError: onErrorQueryCommon,
    }),
  })
}
