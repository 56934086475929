import React from "react";
import "./Details.scss";

const Details = (props) => {
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(date.getFullYear()).slice(2); // Get last two digits of the year
  
    return `${day}-${month}-${year}`;
  }
  
  return (
    <div className="details-content box-bg">
      <div className="h3 fw-semibold">Details</div>
      {/* <table className="table">  
          <tbody>
            {detailsData.map((item) => (
              <tr key={item.id}> 
                <td><b>{item.title}: </b></td>
                <td><span>{item.desc}</span></td> 
              </tr>
            ))}
          </tbody>
        </table> */}
        <ul style={{
          marginLeft: 0
        }}>
          <li>
            <b>Requester name: </b>
            <span>{props.task ? props.task.customer_name : ""}</span>
          </li>
          <li>
            <b>Location: </b>
            <span>{props.task ? props.task.location : ""}</span>
          </li>
          <li>
            <b>Allow Overtime: </b>
            <span>
              {props.task
                ? props.task.allow_overtime == 0
                  ? "No"
                  : "Yes"
                : ""}
            </span>
          </li>
          <li>
            <b>Submission date: </b>
            <span>{props.task ? formatDate( props.task.local_creation_time) : ""}</span>
          </li>
          <li>
            <b>Request type: </b>
            <span>{props.task ? props.task.priority : ""}</span>
          </li>
        </ul>
    </div>
  );
};

export default Details;
