import React, { useState } from "react";
import "./Description.scss";
import { Link } from "react-router-dom";

const Description = (props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="description-content box-bg">
      <div>
        <div className="h3 fw-semibold" style={{ paddingBottom: "10px" }}>
          Description
        </div>
        <div className="wrapper">
          {
            <div
              id="main-content"
              style={{
                width: "100%",
                height: "250px",
                overflow: "hidden",
              }}
              dangerouslySetInnerHTML={{
                __html: props.task ? props.task.description : "",
              }}
            />
          }
        </div>
      </div>
      <div className="mt-auto">
        <a
          style={{
            marginTop: "1rem",
            cursor: "pointer",
            display: props.task && props.task.description?.length < 200 ? "none" : "block",
          }}
          onClick={(e) => {
            if (!expanded)
              document.getElementById("main-content").style.height = "auto";
            else document.getElementById("main-content").style.height = "250px";
            setExpanded(!expanded);
          }}
          to="/"
          className="show-more"
        >
          {expanded ? "Show Less" : "Show More"}
        </a>
      </div>
    </div>
  );
};

export default Description;
