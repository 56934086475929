import ReactApexChart from "react-apexcharts";

const ApexBarChart2 = ({data}) => {
	const chartData = {
		options: {
			chart: {
				type: "bar",
				stacked: true,
			},
			xaxis: {
				categories: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
				labels: {
					rotateAlways: true,
					rotate: -45,
					style: {
						fontSize: '11px', // Set the font size for labels
					},
				},
				reversed: true,

			},
			colors:  [
				"#F9CBFD",
				"#D1DCFD",
				"#819EFB",
				"#546FC3",
				"#3A5788",
				"#253057",
			  ].reverse(),
			plotOptions: {
				bar: {
					reversed: true,
					horizontal: false,
					dataLabels: {
						reversed: true,
						total: {
							reversed: true,
							enabled: true,
							show: true,
							label: "Total:",
							style: {
								fontSize: "13px",
								fontWeight: 400,
							},
						},
					},
				},
			},
			legend: {
				show: false, // Set show to false to hide the legend
			},
		},
		series: data,
	};

	return (
		<div
			style={{ paddingTop: 20, position: "absolute", bottom: 0, left: 0, right: 30,  paddingLeft: 0 }}
			className="wrapper"
		>
			<div className="stacked-bar-chart">
				<ReactApexChart
					options={chartData.options}
					series={chartData.series}
					type="bar"
					height={260}
				/>
			</div>
	</div>
	);
}

export default ApexBarChart2;
