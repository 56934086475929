import { useMutation, useQuery } from "@tanstack/react-query";

import {
  getTotalRequestsLastQuarter,
  getFirstResponseTimeLastQuarter,
  getOutstandingRequestsLastQuarter,
  getRequestUrgency,
  getTaskLevelMetrics,
  getAllRequests,
  getRequestDetail,
  getTaskTimeWorked,
  getCities,
  getFiscalYears,
  getHoursWorkedStat,
  getRequestsStat,
  getMetKPIs,
  getFirstResponseTimeKPIs,
  getConfirmationTimeKPIs,
  getLogin,
  verifyOTP,
  getLoggedInUser,
  getCompanyName,
  passwordReset,
  getCustomerContacts,
  resetPassword
} from "../services/stats";

export const useGetTotalRequestsLastQuarter = () => {
  return useQuery({
    queryKey: ["getTotalRequestsLastQuarter"],
    queryFn: getTotalRequestsLastQuarter,
  });
};

export const useGetFirstResponseTimeLastQuarter = () => {
  return useQuery({
    queryKey: ["getFirstResponseTimeLastQuarter"],
    queryFn: getFirstResponseTimeLastQuarter,
  });
};

export const useGetOutstandingRequestsLastQuarter = () => {
  return useQuery({
    queryKey: ["getOutstandingRequestsLastQuarter"],
    queryFn: getOutstandingRequestsLastQuarter,
  });
};

export const useGetRequestUrgency = () => {
  return useQuery({
    queryKey: ["getRequestUrgency"],
    queryFn: getRequestUrgency,
  });
};

export const useGetTaskLevelMetrics = () => {
  return useQuery({
    queryKey: ["getTaskLevelMetrics"],
    queryFn: getTaskLevelMetrics,
  });
};

export const useGetAllRequests = (data) => {
  return useQuery({
    queryKey: ["getAllRequests", data],
    queryFn: () => getAllRequests(data),
  });
};

export const useGetRequestDetail = (name) => {
  return useQuery({
    queryKey: ["getRequestDetail", name],
    queryFn: async () => {
      const navigate = name.navigate;
      const data  = await getRequestDetail(name.name);
      if (data.customer.trim() != localStorage.getItem("company").trim()) navigate("/requests", {replace: true})
      return data
    },
  });
};

export const useGetTaskTimeWorked = (task) => {
  return useQuery({
    queryKey: ["getTaskTimeWorked", task],
    queryFn: () => {
      return getTaskTimeWorked(task);
    },
  });
};

export const useGetCities = () => {
  return useQuery({
    queryKey: ["getCities"],
    queryFn: getCities,
  });
};

export const useGetFiscalYears = () => {
  return useQuery({
    queryKey: ["getFiscalYears"],
    queryFn: () => {
      return getFiscalYears();
    },
  });
};

export const useGetHoursWorkedStat = (data) => {
  return useQuery({
    queryKey: ["getHoursWorkedStat", data],
    queryFn: () => {
      return getHoursWorkedStat({
        year: data.year1,
        location: data.city1,
        priority: data.priority1,
      });
    },
  });
};

export const useGetRequestsStat = (data) => {
  return useQuery({
    queryKey: ["getRequestsStat", data],
    queryFn: () => {
      return getRequestsStat({
        year: data.year2,
        location: data.city2,
        priority: data.priority2,
        company: localStorage.getItem("company"),
      });
    },
  });
};

export const useGetMetKPIs = (data) => {
  console.log(data);
  return useQuery({
    queryKey: ["getMetKPIs", data],
    queryFn: () => {
      return getMetKPIs({
        year: data.year3,
        location: data.city3,
        priority: data.priority3,
        company: localStorage.getItem("company"),
      });
    },
  });
};

export const useGetFirstResponseTimeKPIs = (data) => {
  return useQuery({
    queryKey: ["getFirstResponseTimeKPIs", data],
    queryFn: () => {

      console.log("first response time hook");
      return getFirstResponseTimeKPIs({
        year: data.year3,
        location: data.city3,
        priority: data.priority3,
        company: localStorage.getItem("company")
      });
    },
  });
};

export const useGetConfirmationTimeKPIs = (data) => {
  return useQuery({
    queryKey: ["getConfirmationTimeKPIs", data],
    queryFn: () => {
      console.log("confirmation response time hook");
      return getConfirmationTimeKPIs({
        year: data.year3,
        location: data.city3,
        priority: data.priority3,
        company: localStorage.getItem("company"),
      });
    },
  });
};

export const useGetLogin = () => {
  return useMutation({
    mutationFn: async (creds) => {
      const data = await getLogin(creds);
      console.log(data)
      return data;
    },
  });
};


export const useVerifyOTP = () => {
  return useMutation({
    mutationFn: async (otp) => {
      const data = await verifyOTP(otp);
      console.log(data)
      return data;
    },
  });
};


export const useGetLoggedInUser = (email) => {
  return useQuery({
    queryKey: ["getLoggedInUser", email],
    queryFn: () => {
      return getLoggedInUser(email);
    },
  });
};




export const useGetCompanyName = (email) => {
  return useQuery({
    queryKey: ["getCompanyName", email],
    queryFn: () => getCompanyName(email),
  });
};

export const usePasswordReset = () => {
  return useMutation({
    mutationFn: async (_data) => {
      const data = await passwordReset(_data);
      return data;
    },
  });
};

export const useGetCustomerContacts = () => {
  return useQuery({
    queryKey: ["getCustomerContacts", ],
    queryFn: () => {
      return getCustomerContacts();
    },
  });
};


export const usePasswordResetWithCreds = () => {
  return useMutation({
    mutationFn: async (passwordResetPayload) => {
      const data = await resetPassword(passwordResetPayload);
      return data;
    },
  });
};