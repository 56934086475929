import { toast } from 'react-toastify'

export const onErrorQueryCommon = (error) => {
  // const err = error 
  // console.log(error)
  // if (err.response?.status === 401) {
  //   localStorage.removeItem('authToken')
  //   // todo fix
  //   // eslint-disable-next-line no-restricted-globals
  //   location.reload()
  //   toast.error('Your session has expired. Please login again.')
  //   return
  // }

  // const authToken = localStorage.getItem('authToken')
  // if (!authToken) {
  //   // todo fix
  //   // eslint-disable-next-line no-restricted-globals
  //   location.reload()
  //   return
  // }

  // toast.error('Something went wrong fetching data')
}
