import React from "react";
import { Link, useLocation } from "react-router-dom";

const customTitles = {
    "dashboard": "",
    "requests": "Requests",
    "work-visit": "Work Visit",
    "invoices-statements": "Invoices & Statements",
  };
  

function Breadcrumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const showDashboard = pathnames.includes("dashboard") || pathnames.includes("requests") || pathnames.includes("invoices-statements");

  return (
    <div className="breadcrumbs">
      {showDashboard || pathnames.length === 0 ? (
        <Link to="/">{customTitles.dashboard}</Link>
      ) : null}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return (
          <React.Fragment key={name}>
            {isLast ? (
              <span>{customTitles[name]}</span>
            ) : (
              <React.Fragment>
                <Link to={routeTo}>{customTitles[name]}</Link>
                {isLast ? null : <span> / </span>}
              </React.Fragment>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default Breadcrumbs;
